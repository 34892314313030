












































import { FormProfileUnlockTs } from './FormProfileUnlockTs';
export default class FormProfileUnlock extends FormProfileUnlockTs {}
